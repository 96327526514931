<template>
	<div class="main">
		<ori-header />
		<div class="search-detail">
			<div class="search-background">
				<div class="search container">
					<a href="#">
						<img src="@/assets/images/homePage/logo.webp" alt="" />
					</a>
					<el-input placeholder="请输入内容">
						<el-button slot="append" icon="el-icon-search"></el-button>
					</el-input>
					<div class="search-mode">
						<el-checkbox>只搜标题</el-checkbox>
						<el-checkbox>关键词不分词</el-checkbox>
					</div>
					<div class="search-hot">
						<span class="tip">热门搜索：</span>
						<span>元宇宙</span>
						<span>电子烟</span>
						<span>机器人</span>
						<span>用工成本</span>
						<span>Web3.0</span>
						<span>专精特新</span>
					</div>
				</div>
			</div>
			<div class="filter-detail container">
				<div class="filter-content">
					<div class="filter-item">
						<div class="filter-label">
							<span>
								<img src="@/assets/images/homePage/report.png" alt="" />报告类型
							</span>
						</div>
						<div class="filter-options">
							<el-checkbox>行业研究</el-checkbox>
							<el-checkbox>公司研究</el-checkbox>
							<el-checkbox>招股说明书</el-checkbox>
							<el-checkbox>年报季报</el-checkbox>
							<el-checkbox>政策法规</el-checkbox>
							<el-checkbox>宏观策略</el-checkbox>
							<el-checkbox>管理咨询</el-checkbox>
							<el-checkbox>其他报告(期货咨询晨会)</el-checkbox>
						</div>
					</div>
					<div class="filter-item">
						<div class="filter-label">
							<span>
								<img src="@/assets/images/homePage/label.png" alt="" />特色标签
							</span>
						</div>
						<div class="filter-options">
							<el-checkbox>新鲜出炉</el-checkbox>
							<el-checkbox>深度研究</el-checkbox>
							<el-checkbox>热门</el-checkbox>
							<el-checkbox>英文</el-checkbox>
							<el-checkbox>非劵商</el-checkbox>
						</div>
					</div>
					<div class="filter-item">
						<div class="filter-label">
							<span>
								<img
									src="@/assets/images/homePage/classify.png"
									alt=""
								/>行业分类
							</span>
						</div>
						<div class="filter-options">
							<el-checkbox>科技传媒</el-checkbox>
							<el-checkbox>大消费</el-checkbox>
							<el-checkbox>健康医疗</el-checkbox>
							<el-checkbox>金融地产</el-checkbox>
							<el-checkbox>能源矿产</el-checkbox>
							<el-checkbox>工业制造</el-checkbox>
							<el-checkbox>交通物流</el-checkbox>
							<el-checkbox>公共服务</el-checkbox>
							<el-checkbox>农林牧渔</el-checkbox>
							<el-checkbox>综合其他</el-checkbox>
						</div>
					</div>
					<div class="filter-item">
						<div class="filter-label">
							<span>
								<img
									src="@/assets/images/homePage/releaseTime.png"
									alt=""
								/>发布时间
							</span>
						</div>
						<div class="filter-options">
							<span
								class="filter-time"
								@click="filterTimeActive = 1"
								:class="{ filterTimeActive: filterTimeActive === 1 }"
								>近三天</span
							>
							<span
								class="filter-time"
								@click="filterTimeActive = 2"
								:class="{ filterTimeActive: filterTimeActive === 2 }"
								>近一周</span
							>
							<span
								class="filter-time"
								@click="filterTimeActive = 3"
								:class="{ filterTimeActive: filterTimeActive === 3 }"
								>近一月</span
							>
							<span
								class="filter-time"
								@click="filterTimeActive = 4"
								:class="{ filterTimeActive: filterTimeActive === 4 }"
								>近半年</span
							>
							<span
								class="filter-time"
								@click="filterTimeActive = 5"
								:class="{ filterTimeActive: filterTimeActive === 5 }"
								>近一年</span
							>
							<el-date-picker
								v-model="time"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								size="mini"
							>
							</el-date-picker>
						</div>
					</div>
					<div class="filter-item">
						<div class="filter-label">
							<span>
								<img src="@/assets/images/homePage/screen.png" alt="" />更多筛选
							</span>
						</div>
						<div class="filter-options">
							<span class="filter-page">
								报告页数:
							</span>
							<el-radio-group v-model="page">
								<el-radio :label="1">大于50页</el-radio>
								<el-radio :label="2">大于20页</el-radio>
								<el-radio :label="3">大于10页</el-radio>
								<el-radio :label="4">大于5页</el-radio>
							</el-radio-group>
							<span class="filter-page">发布机构:</span>
							<el-select v-model="value" placeholder="请选择" size="mini">
								<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								>
								</el-option>
							</el-select>
							<span class="filter-btn"
								><i class="el-icon-refresh-right"></i> 重置</span
							>
						</div>
					</div>
				</div>
			</div>
			<div class="content-detail container">
				<div class="left">
					<div class="report-brief">
						<span class="sort-total">综合排序</span
						><span class="sort-time"
							>时间排序<i class="el-icon-caret-bottom"></i> </span
						><span class="total">共找到<span>10,000+</span>条结果</span>
					</div>
					<div class="report-list">
						<div
							class="item"
							v-for="i in 20"
							:key="i"
							@click="$router.push('/ori-preview')"
						>
							<div class="flexrbc">
								<div class="item-content">
									<div class="item-title">
										<span>
											<span>【莱坊】</span>
											<span>LOGIC: London &amp; South East Q1 2022</span>
										</span>
										<div class="flexr0c">
											<div class="labels">
												<img src="@/assets/images/homePage/new.png" alt="" />
											</div>
											<div class="labels">
												<img src="@/assets/images/homePage/hot.png" alt="" />
											</div>
										</div>
									</div>
									<div class="item-badge">
										<span class="state">金融地产</span
										><span class="state">5页·2M</span>
										<div class="time">
											<i class="el-tooltip" tabindex="0">2022-05-05</i>
										</div>
										<div class="operation">
											<i class="el-tooltip" tabindex="0">莱坊</i
											><i class="el-tooltip" tabindex="0">晚风还在身旁</i>
										</div>
									</div>
								</div>
								<div class="item-download">
									下载
									<img src="@/assets/images/homePage/download1.png" alt="" />
									<img src="@/assets/images/homePage/download2.png" alt="" />
								</div>
							</div>
							<div class="item-desc">
								<p>
									2021<em>元</em><em>宇宙</em>研究报告 “小”游戏到“大”<em
										>宇宙</em
									>

									2 目 录 CONTENTS 什么是<em>元</em><em>宇宙</em>？<em>元</em
									><em>宇宙</em>因何发展？ <em>元</em
									><em>宇宙</em>去往何方？,<em>元</em><em>宇宙</em>理性之思 0102
									0304 概念出现-<em>元</em
									><em>宇宙</em>，一个平行于现实世界的虚拟空间
									30年前的小说《雪崩》中描绘出了一个平行于现实世界的虚拟空间——<em
										>元</em
									><em>宇宙</em>（Metaverse）。,6 目 录 CONTENTS 什么是<em
										>元</em
									><em>宇宙</em>？<em>元</em><em>宇宙</em>因何发展？ <em>元</em
									><em>宇宙</em>去往何方？,13 目 录 CONTENTS 什么是<em>元</em
									><em>宇宙</em>？<em>元</em><em>宇宙</em>因何发展？ <em>元</em
									><em>宇宙</em>去往何方？,以人 为本 18 目 录 CONTENTS 什么是<em
										>元</em
									><em>宇宙</em>？<em>元</em><em>宇宙</em>因何发展？ <em>元</em
									><em>宇宙</em>带来了什么？
								</p>
								<p>查看全部内容&gt;</p>
							</div>
						</div>
						<el-pagination background layout="prev, pager, next" :total="1000">
						</el-pagination>
					</div>
				</div>
				<div class="right">
					<div class="items">
						<div class="title">
							<span>猜你喜欢</span>
						</div>
						<div
							class="item"
							v-for="i in 4"
							:key="i"
							@click="$router.push('/ori-preview')"
						>
							<div class="name">
								奢侈品皮具行业研究报告（奢侈品皮具：奢侈品、皮具箱包、时尚消费、轻奢、LV、Gucci、中国市场）
							</div>
							<div class="desc">
								<span class="time">2022-01-04</span>
								<span class="source">洞见研报</span>
							</div>
						</div>
					</div>
					<img src="@/assets/images/homePage/banner.jpg" alt="" />
					<div class="items">
						<div class="title">
							<span>热门关注</span>
						</div>
						<div
							class="item"
							v-for="i in 6"
							:key="i"
							@click="$router.push('/ori-preview')"
						>
							<div class="name">
								奢侈品皮具行业研究报告（奢侈品皮具：奢侈品、皮具箱包、时尚消费、轻奢、LV、Gucci、中国市场）
							</div>
							<div class="desc">
								<span class="time">2022-01-04</span
								><span class="source">洞见研报</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<page-foot />
		<or-code />
	</div>
</template>

<script>
import PageFoot from '../../../components/ori-com/PageFoot.vue'
import OrCode from '../../../components/ori-com/Orcode.vue'
export default {
	components: {
		PageFoot,
		OrCode,
	},
	data() {
		return {
			time: '',
			page: 1,
			options: [
				{
					value: '选项1',
					label: '黄金糕',
				},
				{
					value: '选项2',
					label: '双皮奶',
				},
				{
					value: '选项3',
					label: '蚵仔煎',
				},
				{
					value: '选项4',
					label: '龙须面',
				},
				{
					value: '选项5',
					label: '北京烤鸭',
				},
			],
			value: '',
			filterTimeActive: 0,
		}
	},
}
</script>

<style scoped lang="scss">
.main {
	background: #f7f7f7;
	.container {
		width: 1200px;
		margin: auto;
	}
	.search-detail {
		.search-background {
			height: 68px;
			background: #0e273e;
			.search {
				display: flex;
				a {
					img {
						width: 220px;
						height: 40px;
						margin-top: 14px;
					}
				}
				.el-input {
					width: 440px;
					margin: 14px 15px;
					.el-button {
						font-size: 20px;
						width: 40px;
						text-align: center;
						padding: 0;
						line-height: 40px;
						background: #fff;
					}
				}
				.search-mode {
					display: flex;
					flex-direction: column;
					margin-top: 14px;
					.el-checkbox {
						color: #fff;
					}
				}
				.search-hot {
					color: #fff;
					font-size: 13px;
					margin-top: 24px;
					span {
						cursor: pointer;
						margin-right: 9px;
					}
					.tip {
						margin-right: 0;
						margin-left: 5px;
					}
					span:hover {
						color: #327bfa;
					}
					.tip:hover {
						color: #fff;
					}
				}
			}
		}
		.filter-detail {
			padding: 20px;
			background: #fff;
			margin: 20px auto;
			font-size: 14px;
			.filter-content {
				border: 1px solid rgba(0, 0, 0, 0.1);
				.filter-item {
					display: flex;
					align-items: center;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					.filter-label {
						height: 50px;
						background: #eaf1fe;
						width: 120px;
						line-height: 50px;
						text-align: center;
						span {
							img {
								width: 20px;
								height: 20px;
								margin-right: 5px;
							}
						}
					}
					.filter-options {
						padding: 0 20px;
						line-height: 49px;
						.el-checkbox {
							margin-right: 20px;
						}
						.filter-time {
							width: 68px;
							cursor: pointer;
							height: 28px;
							display: inline-block;
							border: 1px solid rgba(0, 0, 0, 0.1);
							margin-right: 20px;
							text-align: center;
							line-height: 26px;
							border-radius: 4px;
							font-size: 12px;
						}
						.filter-time:hover {
							color: #327bfa;
							border-color: #c2d7fe;
							background-color: #ebf2ff;
						}
						.filterTimeActive {
							color: #327bfa;
							border-color: #c2d7fe;
							background-color: #ebf2ff;
						}
						.filter-page {
							margin-right: 10px;
						}
						.el-radio-group {
							margin-right: 20px;
						}
						.filter-btn {
							color: #327bfa;
							border: 1px solid #c6e2ff;
							background-color: #ecf5ff;
							cursor: pointer;
							width: 90px;
							height: 40px;
							display: inline-block;
							margin: 0 20px;
							border-radius: 4px;
							text-align: center;
							line-height: 40px;
						}
					}
				}
				.filter-item:last-child {
					border-bottom: 0;
				}
			}
		}
		.content-detail {
			display: flex;
			margin-bottom: 20px;
			.left {
				width: 880px;
				margin-right: 20px;
				background: #fff;
				.report-brief {
					margin: 0 20px;
					padding: 10px 0;
					height: 44px;
					line-height: 24px;
					border-bottom: 1px solid #eef2f6;
					font-size: 14px;
					display: flex;
					.sort-total {
						margin-right: 20px;
						cursor: pointer;
					}
					.sort-time {
						cursor: pointer;
						i {
							margin-left: 5px;
						}
					}
					.sort-time:hover {
						color: #327bfa;
					}
					.total {
						flex: 1;
						text-align: right;
						span {
							color: #327bfa;
						}
					}
				}
				.report-list {
					margin: 0 20px;
					.item {
						position: relative;
						padding: 13px 0;
						border-bottom: 1px solid #eef2f6;
						cursor: pointer;
						.flexrbc {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							align-items: center;
							.item-content {
								width: 85%;
								margin-right: 0;
								.item-title {
									width: 90%;
									display: flex;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
									font-size: 16px;
									justify-content: flex-start;
									margin-right: 0;
									.flexr0c {
										display: flex;
										.labels {
											img {
												height: 18px;
												margin-left: 5px;
												vertical-align: middle;
											}
										}
									}
								}
								.item-badge {
									display: flex;
									font-size: 12px;
									.state {
										display: inline-block;
										padding: 2px 5px;
										color: #d7403d;
										background: #fff1f0;
										margin-right: 5px;
										line-height: 21px;
									}
									i {
										color: #a8b4bf;
										font-style: normal;
										display: inline-block;
										padding: 2px 5px;
										font-size: 14px;
									}
								}
							}
							.item-download {
								width: 120px;
								height: 32px;
								line-height: 32px;
								color: #fff;
								text-align: center;
								background: #327bfa;
								border-radius: 16px;
								font-size: 14px;
								cursor: pointer;
								img {
									width: 20px;
									height: 20px;
									margin: 0 5px;
								}
							}
						}
						.item-desc {
							margin-top: 10px;
							background: #f5f7f9;
							color: #394551;
							line-height: 22px;
							border-radius: 4px;
							font-size: 14px;
							border: 1px solid rgba(0, 0, 0, 0.1);
							p {
								margin-bottom: 0;
							}
							p:first-child {
								padding: 10px 10px 0 10px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 6;
								-webkit-box-orient: vertical;
								border-bottom: 1px solid rgba(0, 0, 0, 0.1);
								em {
									color: red;
									font-style: normal;
								}
							}
							p:last-child {
								padding: 12px 0;
								cursor: pointer;
								text-align: center;
							}
						}
					}
					.item:hover .item-title {
						color: #327bfa;
					}
					.el-pagination {
						padding: 20px;
						text-align: center;
					}
				}
			}
			.right {
				width: 300px;
				// background: #fff;
				.items {
					padding: 15px;
					margin-bottom: 20px;
					background: #fff;
					.title {
						padding-left: 21px;
						font-size: 16px;
						line-height: 22px;
						background: url(~@/assets/images/homePage/slash.png) no-repeat 0;
						background-size: 13px 12px;
						span {
							color: #327bfa;
							cursor: pointer;
						}
					}
					.item {
						padding-bottom: 12px;
						border-bottom: 1px solid #eef2f6;
						cursor: pointer;
						font-size: 14px;
						.name {
							padding: 10px 0 8px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						.desc {
							display: flex;
							color: #a8b4bf;
							justify-content: flex-start;
							span {
								margin-right: 20px;
							}
						}
					}
					.item:hover {
						color: #327bfa;
					}
				}
				img {
					width: 300px;
					height: 210px;
					margin-bottom: 20px;
				}
			}
		}
	}
}
</style>
